/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { AxiosError } from 'axios';
import { getProfile, getSelectedLicenseGroupId, getTenantManagerGroupsById } from 'app/store/reducers';
import {
	errorCodes,
	EXPIRED_GRACE_PERIOD,
	getFormsUrl,
	getJntUrl,
	getLogApiUrl,
	getWfxUrl,
	responseError,
	SAMPLE_FORMS_GROUP,
	SAMPLE_FORMS_GROUP_ID,
	SAMPLE_WORKFLOWS_GROUP,
	SAMPLE_WORKFLOWS_GROUP_ID
} from 'app/utils/helpers';

import _ from '@lodash';
import { AppThunk } from 'app/store';
import {
	AdminInfo,
	Device,
	DeviceGroup,
	FormGroup,
	FormOverview,
	LicenseGroupData,
	Log,
	PendingUser,
	Profile,
	PublicId,
	TenantGroup,
	User,
	UserGroup,
	Workflow,
	WorkflowGroup,
	Role,
	InputNode,
	SampleWorkflow,
	Notification,
	NotificationGroup,
	App,
	LicenseGroupResource,
	AppGroup,
	JobName,
	JobNameGroup
} from 'app/store/types';
import { getTimeZones } from '@vvo/tzdb';
import { UNGROUPED_APP_GROUP_ID, UNGROUPED_FORMS_GROUP_ID } from 'app/utils/default-groups';
import { getPlanDomain } from 'app/utils/tenant-plan';
import {
	adminInfoSchema,
	appGroupsSchema,
	appsSchema,
	deviceGroupsSchema,
	devicesSchema,
	entitlementsSchema,
	formGroupsSchema,
	formsSchema,
	inputNodesSchema,
	jobNameGroupsSchema,
	jobNamesSchema,
	licenseSchema,
	pendingUsersSchema,
	rolesSchema,
	sampleWorkflowsSchema,
	userGroupsSchema,
	usersSchema,
	workflowGroupsSchema,
	workflowsSchema
} from 'app/utils/schemas';
import * as appActions from './app.actions';
import * as profileActions from './profile.actions';

// HACK::allow switching license group plan for testing
const mockSelectedLicenseGroupPlan = localStorage.getItem('mockPlan') as PublicId | undefined;

// HACK::allow switching order type for testing
const mockSelectedLicenseGroupOrderType = localStorage.getItem('mockOrderType') as
	| LicenseGroupData['orderType']
	| undefined;

// HACK::allow switching to expired license for testing
const mockExpired = localStorage.getItem('mockExpired');

// HELPER: set isCoolOffPeriod based on the order type and the expiration date
const getCoolOffPeriod = (
	orderType: LicenseGroupData['orderType'],
	expirationDate: LicenseGroupData['expirationDate']
): boolean => {
	return (
		!!expirationDate &&
		Date.now() >= expirationDate &&
		Date.now() < expirationDate + EXPIRED_GRACE_PERIOD[orderType]
	);
};

const getTimezoneOffset = (timezoneName: string) => {
	const timeZones = getTimeZones({ includeUtc: true });
	let userTimeZoneData = timeZones.find(tz => tz.name === timezoneName);
	if (!userTimeZoneData) {
		userTimeZoneData = timeZones.find(tz => tz.group.includes(timezoneName));
	}
	return userTimeZoneData ? userTimeZoneData.currentTimeOffsetInMinutes : -new Date().getTimezoneOffset();
};
// HACK::reshape data returned from backend
const massageRawLicenseGroup = (rawLicenseGroup: any) => {
	const timezoneOffset = getTimezoneOffset(rawLicenseGroup.timezone);

	const licenseGroupData: LicenseGroupData = {
		id: rawLicenseGroup.id,
		slug: rawLicenseGroup.slug,
		domain: `https://${rawLicenseGroup.slug}.tenant.${getPlanDomain(rawLicenseGroup.plan)}`,
		name: rawLicenseGroup.name,
		quantity: rawLicenseGroup.quantity,
		capacity: rawLicenseGroup.capacity,
		userQuantity: rawLicenseGroup.userQuantity,
		userCapacity: rawLicenseGroup.userCapacity,
		materialNumber: rawLicenseGroup.materialNumber,
		// HACK::publicId can come in upper- or lower-case
		catalogPublicId: mockSelectedLicenseGroupPlan ?? rawLicenseGroup.catalogPublicId.toUpperCase(),
		orderType: mockSelectedLicenseGroupOrderType ?? rawLicenseGroup.orderType.toUpperCase(),
		orderNumber: rawLicenseGroup.orderNumber,
		status: rawLicenseGroup.status,
		purchaseCode: rawLicenseGroup.purchaseCode,
		expirationDate: mockExpired ? +mockExpired : rawLicenseGroup.expirationDate,
		userExpirationDate: mockExpired ? +mockExpired : rawLicenseGroup.userExpirationDate,
		isCoolOffPeriod:
			getCoolOffPeriod(
				mockSelectedLicenseGroupOrderType ?? rawLicenseGroup.orderType.toUpperCase(),
				mockExpired ? +mockExpired : rawLicenseGroup.expirationDate
			) ?? false,
		language: rawLicenseGroup.language,
		region: rawLicenseGroup.region,
		timezone: rawLicenseGroup.timezone,
		timezoneOffset,
		managerEmails: rawLicenseGroup.managerEmails,
		parentId: rawLicenseGroup?.parent,
		adminData: rawLicenseGroup.adminData,
		dateCreated: rawLicenseGroup.dateAdded,
		authMethod: rawLicenseGroup.authMethod,
		licenseUsage: rawLicenseGroup.licenseUsage,
		suspension: rawLicenseGroup.suspensions ?? {},
		// preferences: rawLicenseGroup.preferences ?? {}, pretty sure these manager notification preferences were moved to manager groups
		plan: rawLicenseGroup.plan
	};

	return licenseGroupData;
};

const massageRawTenantManagerGroup = (rawTenantGroup: any) => {
	const tenantGroup: TenantGroup = {
		id: rawTenantGroup.id,
		parentId: rawTenantGroup?.parentId,
		protected: rawTenantGroup?.protected,
		children: rawTenantGroup?.children,
		parentName: rawTenantGroup?.parentName,
		managerEmails: rawTenantGroup?.managerEmails?.map((manager: any) => ({
			firstName: manager?.firstName,
			lastName: manager?.lastName,
			email: manager?.email,
			status: manager?.status,
			id: manager?.id
		})),
		name: rawTenantGroup.name,
		managerEvents: rawTenantGroup?.preferences,
		isGroup: true
	};

	return tenantGroup;
};

const massageRawAdminInfo = (rawAdminInfo: any) => {
	const adminInfo: AdminInfo = {
		primary: rawAdminInfo.primary,
		secondary: rawAdminInfo.secondary
	};

	return adminInfo;
};

const massageRawUser = (rawUser: any) => {
	const user: User = {
		id: rawUser.id,
		email: rawUser.email,
		// HACK-ish::fall back to `username` and empty string for firstName and lastName - added for cognito users
		firstName: rawUser.firstName || rawUser.username || rawUser.email.split('@')[0] || '',
		lastName: rawUser.lastName || '',
		dateAdded: rawUser.dateAdded,
		userGroupId: rawUser.groupList,
		blocked: rawUser.status === 'BLOCKED',
		// DEV NOTE::in theory this should always be false (because `massageRawPendingUser` handled pending users)
		pending: rawUser.status === 'PENDING',
		username: rawUser.userName,
		roles: rawUser.roles,
		licensed: rawUser.licensed,
		nonMfpLicenseCapacity: rawUser.nonMFPLicenseCapacity,
		nonMfpLicenseQuantity: rawUser.nonMFPLicenseQuantity
	};

	return user;
};

const massageRawPendingUser = (rawPendingUser: any) => {
	const pendingUser: PendingUser = {
		id: rawPendingUser.id,
		email: rawPendingUser.email,
		token: rawPendingUser.token,
		dateAdded: rawPendingUser.dateAdded,
		userGroupId: rawPendingUser.groupList ? rawPendingUser.groupList : [],
		blocked: false,
		// add `pending` prop for distinguishing if this list gets mixed with regular users
		pending: true,
		roles: rawPendingUser.roles,
		licensed: rawPendingUser.licensed,
		nonMfpLicenseCapacity: rawPendingUser.nonMFPLicenseCapacity,
		nonMfpLicenseQuantity: rawPendingUser.nonMFPLicenseQuantity
	};

	return pendingUser;
};

const massageRawUserGroup = (rawUserGroup: any) => {
	const userGroup: UserGroup = {
		id: rawUserGroup.id,
		name: rawUserGroup.name,
		dateUpdated: rawUserGroup.updatedAt,
		groupOrder: rawUserGroup.groupOrder,
		collapse: rawUserGroup.collapse,
		roles: rawUserGroup?.roles ?? [],
		defaultUser: rawUserGroup.defaultUser
	};

	return userGroup;
};

const massageRawDevice = (rawDevice: any) => {
	const device: Device = {
		serial: rawDevice.id,
		friendlyName: rawDevice.name,
		name: rawDevice.model ? `bizhub ${rawDevice.model}` : '',
		model: rawDevice.model,
		location: rawDevice.location,
		STCApp: rawDevice.STCApp ?? false,
		stratusApp: rawDevice.stratusApp ?? false,
		ipAddress: rawDevice.ip,
		serialNumber: rawDevice.serial,
		description: rawDevice.description,
		licensed: rawDevice.licensed,
		deviceGroupId: rawDevice.groupList.length === 1 && rawDevice.groupList[0] === '' ? [] : rawDevice.groupList,
		deviceStatus: rawDevice.status ? rawDevice.status.toLowerCase() : 'complete'
	};
	return device;
};

const massageRawDeviceGroup = (rawDeviceGroup: any) => {
	const deviceGroup: DeviceGroup = {
		id: rawDeviceGroup.id,
		name: rawDeviceGroup.name,
		dateUpdated: rawDeviceGroup.dateUpdated
	};

	return deviceGroup;
};

const massageRawInputNode = (rawInputNode: any) => {
	const inputNode: InputNode = {
		id: rawInputNode.id,
		name: rawInputNode.name,
		licensed: rawInputNode.licensed
	};

	return inputNode;
};

const massageRawRole = (rawRole: any) => {
	const role: Role = {
		id: rawRole.id,
		name: rawRole.name,
		permissions: rawRole.permissions,
		systemRole: rawRole?.systemRole
	};

	return role;
};

const massageRawForm = (rawForms: any[]): { [id: string]: FormOverview } => {
	const forms: { [id: string]: FormOverview } = {};

	rawForms.forEach((rawForm: any, i) => {
		const form: FormOverview = {
			id: rawForm.id,
			title: rawForm.title || `Form-${i + 1}`,
			creator: rawForm.creator,
			editor: rawForm.editor,
			created: rawForm.created,
			updated: rawForm.updated,
			formStatus: rawForm.formStatus,
			valid: rawForm.valid,
			metadataKeys: rawForm.metadataKeys,
			formGroup: { id: rawForm.formGroup.id }
		};

		forms[rawForm.id] = form;
	});

	return forms;
};

const massageRawFormGroup = (rawFormGroups: any): { [id: string]: FormGroup } => {
	const formGroups: { [id: string]: FormGroup } = {};

	rawFormGroups.forEach((rawFormGroup: any) => {
		const formGroup: FormGroup = {
			id: rawFormGroup.id,
			title: rawFormGroup.title,
			groupOrder: rawFormGroup.sortOrder
		};

		formGroups[rawFormGroup.id] = formGroup;
	});

	formGroups[UNGROUPED_FORMS_GROUP_ID] = {
		id: UNGROUPED_FORMS_GROUP_ID,
		title: UNGROUPED_FORMS_GROUP_ID
	};

	return formGroups;
};

const getPageCount = (rawWorkflow: any) => {
	let pageCount = 0;

	if (rawWorkflow.options && rawWorkflow.options.PreviewPageCount) {
		pageCount = +rawWorkflow.options.PreviewPageCount;

		if (Number.isNaN(pageCount)) {
			pageCount = 1;
		}
	}

	return pageCount;
};

export const massageRawWorkflow = (
	rawWorkflow: any,
	profileId?: Profile['id'],
	awsRegion: Profile['awsRegion'] = 'us-east-1'
) => {
	const pageCount = getPageCount(rawWorkflow);
	const wfxApiUrl = getWfxUrl(awsRegion);
	const workflow: Workflow = {
		id: rawWorkflow.id,
		name: rawWorkflow.name,
		status: rawWorkflow.status,
		previews: _.range(0, pageCount).map(
			count =>
				`${wfxApiUrl}/api/wfx/${rawWorkflow.id}/preview/${count + 1}.png?${Date.parse(rawWorkflow.modified)}`
		),
		// have a set shape - and always set yourself as the owner if no one is set yet
		acl: {
			users: rawWorkflow.acl?.users ?? [{ name: profileId, role: 'owner' }],
			groups: rawWorkflow.acl?.groups ?? [],
			devices: rawWorkflow.acl?.devices ?? [],
			deviceGroups: rawWorkflow.acl?.deviceGroups ?? []
		},
		workflowGroupId: rawWorkflow.options?.Group || rawWorkflow.options?.group || '',
		dateUpdated: Date.parse(rawWorkflow.modified)
	};

	return workflow;
};

export const massageRawSampleWorkflow = (rawSampleWorkflow: any) => {
	const sampleWorkflow: SampleWorkflow = {
		id: rawSampleWorkflow.id,
		name: rawSampleWorkflow.name,
		status: rawSampleWorkflow.status,
		description: rawSampleWorkflow.description,
		dateUpdated: Date.parse(rawSampleWorkflow.modified),
		workflowGroupId: SAMPLE_WORKFLOWS_GROUP_ID
	};

	return sampleWorkflow;
};

const massageRawWorkflowGroup = (rawWorkflowGroup: any, profileId?: Profile['id']) => {
	const workflowGroup: WorkflowGroup = {
		id: rawWorkflowGroup.id,
		name: rawWorkflowGroup.name,
		dateUpdated: new Date(rawWorkflowGroup.modified).getTime(),
		dateCreated: rawWorkflowGroup.created ? new Date(rawWorkflowGroup.created).getTime() : undefined,
		groupOrder: rawWorkflowGroup.groupOrder,
		collapse: rawWorkflowGroup.collapse,
		acl: {
			users: rawWorkflowGroup.acl?.users ?? [{ name: profileId, role: 'owner' }],
			groups: rawWorkflowGroup.acl?.groups ?? [],
			devices: rawWorkflowGroup.acl?.devices ?? [],
			deviceGroups: rawWorkflowGroup.acl?.deviceGroups ?? []
		}
	};

	return workflowGroup;
};

const massageRawLog = (rawLog: any) => {
	const log: Log = {
		id: rawLog.SK,
		type: rawLog.Type,
		dateCreated: rawLog.DateAdded,
		severity: rawLog.severity,
		messageKey: rawLog.MessageTranslated,
		errorCode: errorCodes[rawLog.MsgKey as keyof typeof errorCodes] ?? errorCodes.generalError,
		event: rawLog.Action,
		info: rawLog.Info ?? {},
		logLevel: rawLog.LogLevel
	};

	return log;
};

const massageRawEntitlements = (rawEntitlement: any) => {
	const { permissions } = rawEntitlement;
	const { features } = rawEntitlement;

	return {
		permissions,
		features
	};
};
export const massageRawApp = (rawApp: any, profileId?: Profile['id']) => {
	const app: App = {
		id: rawApp.id,
		type: rawApp.type,
		name: rawApp.name,
		description: rawApp.description,
		url: rawApp.url,
		workflowId: rawApp.workflowId,
		nodeId: rawApp.nodeId,
		licensed: rawApp.licensed,
		appGroupId: rawApp.groupList?.length ? rawApp.groupList : [UNGROUPED_APP_GROUP_ID],
		// have a set shape - and always set yourself as the owner if no one is set yet
		acl: {
			users: rawApp.acl?.users ?? [{ name: profileId, role: 'owner' }],
			groups: rawApp.acl?.groups ?? [],
			// DEV NOTE::ATM `devices` and `deviceGroups` are not used for apps
			devices: rawApp.acl?.devices ?? [],
			deviceGroups: rawApp.acl?.deviceGroups ?? []
		},
		aclRole: rawApp.aclRole
	};

	return app;
};

const massageRawAppGroup = (rawAppGroup: any) => {
	const appGroup: AppGroup = {
		id: rawAppGroup.id,
		name: rawAppGroup.name
	};

	return appGroup;
};

export const massageRawNotification = (rawNotification: any) => {
	const notification: Notification = {
		id: rawNotification.id,
		acknowledgedId: `${rawNotification.id}-${rawNotification.dateUpdated}`,
		title: rawNotification.title,
		message: rawNotification.message,
		userGroupIds: rawNotification.userGroupIds,
		startDate: rawNotification.startDate,
		endDate: rawNotification.endDate,
		createdBy: rawNotification.createdBy,
		updatedBy: rawNotification.updatedBy,
		dateCreated: rawNotification.dateCreated,
		dateUpdated: rawNotification.dateUpdated,
		color: rawNotification.color,
		icon: rawNotification.icon
	};

	return notification;
};

const massageRawNotificationGroup = (rawNotificationGroup: any) => {
	const notificationGroup: NotificationGroup = {
		id: rawNotificationGroup.id,
		name: rawNotificationGroup.name
	};

	return notificationGroup;
};

const massageRawJobName = (rawJobName: any) => {
	const jobName: JobName = {
		id: rawJobName.id,
		components: rawJobName.components,
		modified: rawJobName.modified,
		created: rawJobName.created,
		editor: rawJobName.editor,
		creator: rawJobName.creator,
		groupList: rawJobName.groupList,
		nodes: rawJobName.nodes
	};

	return jobName;
};

const massageRawJobNameGroup = (rawJobNameGroup: any) => {
	const jobNameGroup: JobNameGroup = {
		id: rawJobNameGroup.id,
		title: rawJobNameGroup.title
	};

	return jobNameGroup;
};

// at some point this may be broken into more specific API calls
export const GET_ADMINED_LICENSE_GROUPS_SUCCESS = 'GET_ADMINED_LICENSE_GROUPS_SUCCESS';
export const GET_MANAGED_LICENSE_GROUPS_SUCCESS = 'GET_MANAGED_LICENSE_GROUPS_SUCCESS';
export const GET_TENANT_MANAGER_GROUPS_SUCCESS = 'GET_TENANT_MANAGER_GROUPS_SUCCESS';
export const FORGET_ADMINED_LICENSE_GROUP_SUCCESS = 'FORGET_ADMINED_LICENSE_GROUP_SUCCESS';
export const GET_LICENSE_GROUP_DATA_SUCCESS = 'GET_LICENSE_GROUP_DATA_SUCCESS';
export const GET_TENANT_BY_ID_SUCCESS = 'GET_TENANT_BY_ID_SUCCESS';
export const GET_PARAGON_DATA_SUCCESS = 'GET_PARAGON_DATA_SUCCESS';
export const GET_TENANT_GROUP_BY_ID_SUCCESS = 'GET_TENANT_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS = 'GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS';
export const GET_SELECTED_TENANT_GROUP_INFO_SUCCESS = 'GET_SELECTED_TENANT_GROUP_INFO_SUCCESS';
export const SET_LOADING_MANAGER_GROUPS = 'SET_LOADING_MANAGER_GROUPS';

// export const getAdminedLicenseGroups = (): AppThunk => async dispatch => {
// 	try {
// 		// TODO::actually populate list
// 		const { data: rawLicenseGroups } = await Promise.resolve({ data: {} });

// 		const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(rawLicenseGroups, massageRawLicenseGroup);

// 		dispatch({
// 			type: GET_ADMINED_LICENSE_GROUPS_SUCCESS,
// 			payload: {
// 				data: licenseGroups
// 			}
// 		});
// 	} catch (error) {
// 		if (error.response?.status === 401) {
// 			// if user not logged in
// 			// dispatch({
// 			// 	type: GET_ADMINED_LICENSE_GROUPS_SUCCESS,
// 			// 	payload: {
// 			// 		data: undefined
// 			// 	}
// 			// });
// 			return;
// 		}
// 		dispatch(appActions.handleError(error));
// 		// re-throw error for handling in <InitializeApp />
// 		throw error;
// 	}
// };

export const getManagedLicenseGroups = (): AppThunk => async dispatch => {
	try {
		const { data: rawLicenseGroups } = await axios.get('/api/tenants');

		const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(rawLicenseGroups, massageRawLicenseGroup);

		dispatch({
			type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
			payload: {
				data: licenseGroups
			}
		});
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
				payload: {
					data: undefined
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getTenantManagerGroups = (): AppThunk => async dispatch => {
	try {
		const { data: rawTenantLicenseGroups } = await axios.get('/api/tenants/tenant-manager-groups');
		// const tenantLicenseGroups: any[] = massageRawTenantManagerGroup(rawTenantLicenseGroups);
		const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
			_.keyBy(rawTenantLicenseGroups, 'id'),
			massageRawTenantManagerGroup
		);
		dispatch({
			type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
			payload: {
				data: tenantGroups
			}
		});
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
				payload: {
					data: []
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getEditedTenantManagerGroups = (id: string): AppThunk => async dispatch => {
	const [{ data: rawTenantLicenseGroups }, { data: EmailInfo }] = await Promise.all([
		axios.get('/api/tenants/tenant-manager-groups'),
		axios.get(`/api/tenants/tenant-manager-group/${id}`)
	]);
	const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
		_.keyBy(rawTenantLicenseGroups, 'id'),
		massageRawTenantManagerGroup
	);
	dispatch({
		type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
		payload: {
			data: tenantGroups
		}
	});
	dispatch({
		type: GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS,
		payload: {
			groupId: id,
			data: EmailInfo
		}
	});
};

// export const forgetAdminedLicenseGroup = (licenseGroupId: LicenseGroupData['id']): AppThunk => async (
// 	dispatch,
// 	getState
// ) => {
// 	dispatch({
// 		type: FORGET_ADMINED_LICENSE_GROUP_SUCCESS,
// 		payload: {
// 			licenseGroupId
// 		}
// 	});
// };
export const forgetAdminedLicenseGroup = (licenseGroupId: LicenseGroupData['id']): AppThunk => async dispatch => {
	try {
		await axios.delete(`/api/user/user-tenants/${licenseGroupId}`);
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const addAdminedLicenseGroup = (slug: LicenseGroupData['slug']): AppThunk => async (dispatch, _getState) => {
	try {
		await axios.patch('/api/user/user-tenants', { slug });
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error?.response?.status === 404) {
				dispatch(appActions.alert('license group to add not found', 'error'));
				throw error;
			} else if (error?.response?.status === 409) {
				dispatch(appActions.alert('license group already added', 'error'));
				throw error;
			}
		}
		dispatch(appActions.handleError(error));
		throw error;
	}
};

export const getSelectedLicenseGroupData = (
	resources: LicenseGroupResource[],
	successFn?: () => void,
	count = 0
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const profile = getProfile(state);
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const formsApiUrl = getFormsUrl(profile?.awsRegion);
	const jntApiUrl = getJntUrl(profile?.awsRegion);

	const resourceCheck = (resource: LicenseGroupResource) => {
		if (resources.length === 0) {
			return true;
		}
		return resources.includes(resource);
	};

	const schemaCheck = (serverData: any, schemaFn: Function, msg: string) => {
		if (serverData && !schemaFn(serverData)) {
			console.log(msg.toUpperCase());
		}
	};

	try {
		const [
			{ data: rawLicenseGroup },
			{ data: rawUsers },
			{ data: rawPendingUsers },
			{ data: rawUserGroups },
			{ data: rawDevices },
			{ data: rawDeviceGroups },
			{ data: rawInputNodes },
			{ data: rawRoles },
			{ data: rawAdminInfo },
			{ data: rawWorkflows },
			{ data: rawSampleWorkflows },
			{ data: rawWorkflowGroups },
			{ data: rawForms }, // Added this line to get the forms data from the new endpoint
			{ data: rawFormGroups }, // Added this line to get the form groups data from the new endpoint
			{ data: rawEntitlements },
			{ data: rawApps },
			{ data: rawAppGroups },
			{ data: rawNotifications },
			{ data: rawNotificationGroups },
			{ data: rawJobNames },
			{ data: rawJobNameGroups }
			// { data: testData }
		] = await Promise.all([
			resourceCheck('license')
				? axios.get(`/api/tenants/${licenseGroupId}/details`)
				: {
						data: undefined as any
				  },
			resourceCheck('users')
				? axios.get(`/api/tenants/${licenseGroupId}/users`)
				: {
						data: undefined as any
				  },
			resourceCheck('pending users')
				? axios.get(`/api/tenants/${licenseGroupId}/pendingUsers`)
				: {
						data: undefined as any
				  },
			resourceCheck('user groups')
				? axios.get(`/api/tenants/${licenseGroupId}/userGroups`)
				: {
						data: undefined as any
				  },
			resourceCheck('devices')
				? axios.get(`/api/tenants/${licenseGroupId}/devices`)
				: {
						data: undefined as any
				  },
			resourceCheck('device groups')
				? axios.get(`/api/tenants/${licenseGroupId}/deviceGroups`)
				: {
						data: undefined as any
				  },
			resourceCheck('input nodes')
				? axios.get(`/api/tenants/${licenseGroupId}/inputNodes`)
				: {
						data: undefined as any
				  },
			resourceCheck('roles')
				? axios.get(`/api/tenants/${licenseGroupId}/roles`)
				: {
						data: undefined as any
				  },
			resourceCheck('admin info')
				? axios.get(`/api/tenants/${licenseGroupId}/adminInfo`)
				: {
						data: undefined as any
				  },
			resourceCheck('workflows')
				? axios.get(`${wfxApiUrl}/api/wfx`).catch(error => {
						return { data: [] };
				  })
				: { data: undefined as any },
			resourceCheck('sample workflows')
				? axios.get(`${wfxApiUrl}/api/samples`).catch(error => {
						return { data: [] };
				  })
				: { data: undefined as any },
			resourceCheck('workflow groups')
				? axios.get(`${wfxApiUrl}/api/groups`).catch(error => {
						return { data: [] };
				  })
				: { data: undefined as any },
			// Comment above and uncomment below to test groups sharing
			// axios.get(`https://feature-groups.wfx.stratus.lol/api/groups`),
			resourceCheck('forms') ? axios.get(`${formsApiUrl}/api/forms`) : { data: undefined as any },
			resourceCheck('form groups') ? axios.get(`${formsApiUrl}/api/groups`) : { data: undefined as any },
			resourceCheck('entitlements') ? axios.get(`/api/user/entitlements`) : { data: undefined as any },
			resourceCheck('apps') ? axios.get('/api/app') : { data: undefined as any },
			resourceCheck('app groups') ? axios.get('/api/app/app-group') : { data: undefined as any },
			resourceCheck('notifications') ? axios.get('/api/notification') : { data: undefined as any },
			resourceCheck('notification groups') ? { data: [] } : { data: undefined as any },
			resourceCheck('job names')
				? axios.get(`${jntApiUrl}/api/portal/jnt/${licenseGroupId}`).catch(error => {
						return { data: [] };
				  })
				: {
						data: undefined as any
				  },
			resourceCheck('job name groups')
				? axios.get(`${jntApiUrl}/api/portal/jntg/${licenseGroupId}`).catch(error => {
						return { data: [] };
				  })
				: {
						data: undefined as any
				  }
		]);

		// console.log({ rawJobs, rawQueues, rawInbox });

		schemaCheck(rawLicenseGroup, licenseSchema, 'License data does not match the schema');
		schemaCheck(rawUsers, usersSchema, 'Users data does not match the schema');
		schemaCheck(rawPendingUsers, pendingUsersSchema, 'Pending users data does not match the schema');
		schemaCheck(rawUserGroups, userGroupsSchema, 'User groups data does not match the schema');
		schemaCheck(rawDevices, devicesSchema, 'Devices data does not match the schema');
		schemaCheck(rawDeviceGroups, deviceGroupsSchema, 'Device groups data does not match the schema');
		schemaCheck(rawInputNodes, inputNodesSchema, 'Input nodes data does not match the schema');
		schemaCheck(rawRoles, rolesSchema, 'Roles data does not match the schema');
		schemaCheck(rawAdminInfo, adminInfoSchema, 'Admin info data does not match the schema');
		schemaCheck(rawForms, formsSchema, 'Forms data does not match the schema');
		schemaCheck(rawFormGroups, formGroupsSchema, 'Form groups data does not match the schema');
		schemaCheck(rawSampleWorkflows, sampleWorkflowsSchema, 'Sample workflows data does not match the schema');
		schemaCheck(rawWorkflows, workflowsSchema, 'Workflows data does not match the schema');
		schemaCheck(rawWorkflowGroups, workflowGroupsSchema, 'Workflow groups data does not match the schema');
		schemaCheck(rawEntitlements, entitlementsSchema, 'Entitlements data does not match the schema');
		schemaCheck(rawApps, appsSchema, 'Apps data does not match the schema');
		schemaCheck(rawAppGroups, appGroupsSchema, 'App groups data does not match the schema');
		schemaCheck(rawJobNames, jobNamesSchema, 'Job names data does not match the schema');
		schemaCheck(rawJobNameGroups, jobNameGroupsSchema, 'Job name groups data does not match the schema');

		// HACK::apply various hacks
		const licenseGroup = rawLicenseGroup ? massageRawLicenseGroup(rawLicenseGroup) : undefined;
		const users: { [id: string]: User } | undefined = rawUsers ? _.mapValues(rawUsers, massageRawUser) : undefined;
		const pendingUsers: { [id: string]: PendingUser } | undefined = rawPendingUsers
			? _.mapValues(rawPendingUsers, massageRawPendingUser)
			: undefined;
		const userGroups: { [id: string]: UserGroup } | undefined = rawUserGroups
			? _.mapValues(rawUserGroups, massageRawUserGroup)
			: undefined;
		const devices: { [id: string]: Device } | undefined = rawDevices
			? _.mapValues(rawDevices, massageRawDevice)
			: undefined;
		const deviceGroups: { [id: string]: DeviceGroup } | undefined = rawDeviceGroups
			? _.mapValues(rawDeviceGroups, massageRawDeviceGroup)
			: undefined;
		const inputNodes: { [id: string]: InputNode } | undefined = rawInputNodes
			? _.mapValues(rawInputNodes, massageRawInputNode)
			: undefined;
		const roles: { [id: string]: Role } | undefined = rawRoles ? _.mapValues(rawRoles, massageRawRole) : undefined;
		const contactInfo: { [id: string]: AdminInfo } | undefined = rawAdminInfo
			? _.mapValues(rawAdminInfo, massageRawAdminInfo)
			: undefined;
		// TODO::add back real data (and remove weird conditional hack) when endpoints are complete
		const forms: { [id: string]: FormOverview } | undefined = rawForms ? massageRawForm(rawForms) : undefined;

		const formGroups: { [id: string]: FormGroup } | undefined = rawFormGroups
			? massageRawFormGroup(rawFormGroups)
			: undefined;
		const sampleWorkflows: { [id: string]: SampleWorkflow } | undefined = rawSampleWorkflows
			? _.mapValues(_.keyBy(rawSampleWorkflows, 'id'), rawSampleWorkflow =>
					massageRawSampleWorkflow(rawSampleWorkflow)
			  )
			: undefined;
		const workflows: { [id: string]: Workflow } | undefined = rawWorkflows
			? _.mapValues(_.keyBy(rawWorkflows, 'id'), rawWorkflow =>
					massageRawWorkflow(rawWorkflow, profile.id, profile?.awsRegion)
			  )
			: undefined;
		const workflowGroups: { [id: string]: WorkflowGroup } | undefined = rawWorkflowGroups
			? _.mapValues(_.keyBy(rawWorkflowGroups, 'id'), rawWorkflowGroup =>
					massageRawWorkflowGroup(rawWorkflowGroup, profile.id)
			  )
			: undefined;
		// const workflowGroups: { [id: string]: WorkflowGroup } = localStorage.getItem('mock')
		// 	? _.mapValues(rawWorkflowGroups, massageRawWorkflowGroup)
		// 	: {};
		const entitlements:
			| {
					permissions: Role['permissions'];
					features: { [feature: string]: boolean };
			  }
			| undefined = rawEntitlements ? massageRawEntitlements(rawEntitlements) : undefined;
		const apps: { [id: string]: App } | undefined = rawApps
			? _.mapValues(_.keyBy(rawApps, 'id'), rawApp => massageRawApp(rawApp, profile.id))
			: undefined;
		const appGroups: { [id: string]: AppGroup } | undefined = rawAppGroups
			? _.mapValues(_.keyBy(rawAppGroups, 'id'), rawAppGroup => massageRawAppGroup(rawAppGroup))
			: undefined;
		const notifications: { [id: string]: Notification } | undefined = rawNotifications
			? _.mapValues(_.keyBy(rawNotifications, 'id'), rawNotification => massageRawNotification(rawNotification))
			: undefined;
		const notificationGroups: { [id: string]: NotificationGroup } | undefined = rawNotificationGroups
			? _.mapValues(_.keyBy(rawNotificationGroups, 'id'), rawNotificationGroup =>
					massageRawNotificationGroup(rawNotificationGroup)
			  )
			: undefined;
		const jobNames: { [id: string]: JobName } | undefined = rawJobNames
			? _.mapValues(rawJobNames, massageRawJobName)
			: undefined;
		const jobNameGroups: { [id: string]: JobNameGroup } | undefined = rawJobNameGroups
			? _.mapValues(rawJobNameGroups, massageRawJobNameGroup)
			: undefined;

		dispatch({
			type: GET_LICENSE_GROUP_DATA_SUCCESS,
			payload: {
				licenseGroupId,
				data: {
					details: licenseGroup,
					contactInfo,
					users,
					pendingUsers,
					userGroups,
					devices,
					deviceGroups,
					inputNodes,
					roles,
					forms,
					formGroups: formGroups
						? {
								...formGroups,
								[SAMPLE_FORMS_GROUP_ID as string]: SAMPLE_FORMS_GROUP
						  }
						: undefined,
					workflows,
					sampleWorkflows,
					workflowGroups: workflowGroups
						? {
								...workflowGroups,
								[SAMPLE_WORKFLOWS_GROUP_ID as string]: SAMPLE_WORKFLOWS_GROUP
						  }
						: undefined,
					entitlements,
					apps,
					appGroups,
					notifications,
					notificationGroups,
					jobNames,
					jobNameGroups
				}
			}
		});
		if (successFn) successFn();
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 403) {
			// re-throw error for handling in <LicenseGroupPageWrapper /> if 403
			throw error;
		}
		// try running this one more time if I get a `401`
		if (error instanceof AxiosError && error.response?.status === 401 && count === 0) {
			await dispatch(profileActions.getProfile());
			// DEV NOTE::I have no idea if there could be an event cycle issue here but...I'm doing this to maybe ensure that the profile is updated before trying again
			setTimeout(() => {
				dispatch(getSelectedLicenseGroupData([], successFn, count + 1));
			}, 0);
		}
		dispatch(appActions.handleError(error));
	}
};

export const getTenantId = (): AppThunk => async (_dispatch, getState) => {
	const id = getSelectedLicenseGroupId(getState());
	return id;
};

export const getTenantById = (id: string): AppThunk => async (dispatch, _getState) => {
	try {
		const {
			data: { adminInfo }
		} = await axios.get(`/api/tenants/contact-info/${id}`);

		dispatch({
			type: GET_TENANT_BY_ID_SUCCESS,
			payload: {
				id,
				adminInfo
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editContactInfo = (data: AdminInfo, id: string): AppThunk => async (dispatch, _getState) => {
	try {
		await axios.patch(`/api/tenants/${id}/contact-data`, data);
		dispatch(appActions.alert('contact info updated', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editSelectedTenant = ({
	name,
	timezone,
	language
}: {
	name?: LicenseGroupData['name'];
	timezone?: LicenseGroupData['timezone'];
	language?: LicenseGroupData['language'];
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		timezone,
		language
	};

	try {
		const response = await axios.patch(`/api/tenants/${tenantId}/settings`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update settings', 'warning'));
		} else {
			dispatch(appActions.alert('settings updated', 'success'));
		}
		dispatch(getSelectedLicenseGroupData([]));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

// export const configureTenant = ({ name }: { name: string }, { region }: { region: string }): AppThunk => async (dispatch, getState) => {
// 	const tenantId = getSelectedLicenseGroupId(getState());

// 	const data = {
// 		name
// 	};

// 	try {
// 		const response = await axios.patch(`/api/v1/tenant/${tenantId}/settings`, data);
// 		// @ts-ignore
// 		if (responseError(response)) {
// 			dispatch(appActions.alert('failed to update settings', 'warning'));
// 		} else {
// 			dispatch(appActions.alert('settings updated', 'success'));
// 		}
// 		dispatch(getSelectedLicenseGroupData());
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const addPrimaryAdmin = ({ name }: { name: string }, action: string): AppThunk => async (
	dispatch,
	_getState
) => {
	const data = {
		name
	};

	try {
		const response = await axios.patch('/api/license-groups/mock-admin-invite/devices', data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update settings', 'warning'));
		} else if (action === 'SEND') {
			dispatch(
				appActions.alert('admin invite sent', 'success', {
					anchorOrigin: { vertical: 'top', horizontal: 'right' }
				})
			);
		} else if (action === 'CANCEL') {
			dispatch(
				appActions.alert('admin invite cancelled', 'success', {
					anchorOrigin: { vertical: 'top', horizontal: 'right' }
				})
			);
		}
		// dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const modifySelectedTenant = ({
	newPublicId,
	newCapacity
}: {
	newPublicId?: PublicId;
	newCapacity: number;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		await axios.post(`/api/tenants/${tenantId}/registration`, {
			capacity: newCapacity,
			...(newPublicId && { catalogItemId: newPublicId })
		});
		dispatch(appActions.alert('license plan updated', 'success'));
		dispatch(getSelectedLicenseGroupData([]));
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error?.response?.data?.message === 'Invalid plan upgrade') {
				dispatch(appActions.alert('license plan invalid', 'warning'));
			} else if (error?.response?.data?.message === 'MAX_CAPACITY_EXCEEDED') {
				dispatch(appActions.alert('license count warning', 'warning'));
			} else {
				dispatch(appActions.handleError(error));
			}
		} else {
			dispatch(appActions.handleError(error));
		}
		dispatch(getSelectedLicenseGroupData([]));
	}
};

export const configureTenant = (
	data: {
		// roles: string[];
		name: string;
		region: string;
		localAdminEmail?: string;
		slug: string;
		authMethod: string;
		fromManager?: boolean;
		userRoles: string[]; // tenant-admin or tenant-manager,
		tenantGroupName?: string;
		tenantParentGroupId?: string;
		metadataUrl: string;
	},
	id: string,
	token?: string
): AppThunk => async (dispatch, getState) => {
	// console.log('configureTenant', data, id, token);
	const {
		name,
		region,
		localAdminEmail,
		slug,
		authMethod,
		fromManager = false,
		userRoles,
		tenantGroupName,
		tenantParentGroupId,
		metadataUrl
	} = data;
	try {
		const payload = {
			name,
			region,
			localAdminEmail,
			slug,
			authMethod,
			fromManager,
			userRoles,
			tenantGroupName,
			tenantParentGroupId,
			metadataUrl
		};

		await axios.patch(`/api/tenants/${id}/configure${token ? `?token=${token}` : ''}`, payload);
		// dispatch(appActions.alert('license plan updated', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const copyTenantData = ({
	targetTenantId,
	copyDataTypes
}: {
	targetTenantId: string;
	copyDataTypes: any;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		await axios.patch(`/api/v1/tenant/${tenantId}/copy`, {
			targetTenantId,
			copyDataTypes
		});
		dispatch(appActions.alert('Tenant data copied', 'success'));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const inviteManager = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/invite-manager`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const resendManagerInvite = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/resend-manager-invite`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const cancelManagerInvite = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/cancel-manager-invite`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite canceled', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const inviteAdmin = (data: {
	adminEmail: string;
	tenantId: string;
	managerInfo: { email: string; firstName: string; lastName: string };
	parentGroupId: string;
	parentGroupName: string;
}): AppThunk => async (dispatch, getState) => {
	const { adminEmail, tenantId, managerInfo, parentGroupId, parentGroupName } = data;
	// const tenantId = getSelectedLicenseGroupId(getState());
	// const { name } = getSelectedLicenseGroupData(getState());
	try {
		await axios.patch(`/api/tenants/${tenantId}/invite-admin`, {
			adminEmail,
			managerInfo,
			parentGroupId,
			parentGroupName
		});
		dispatch(appActions.alert('tenants:invite tenant admin', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
export const resendAdminInvite = (data: {
	adminEmail: string;
	tenantId: string;
	managerInfo: { email: string; firstName: string; lastName: string };
}): AppThunk => async (dispatch, getState) => {
	const { adminEmail, tenantId, managerInfo } = data;
	try {
		await axios.patch(`/api/tenants/${tenantId}/resend-invite`, { adminEmail, managerInfo });
		dispatch(appActions.alert('tenants:invite tenant admin', 'success'));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const inviteTenantGroupManager = (
	managerEmail: string,
	tenantGroupId: string,
	groupName: string
): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${tenantGroupId}/invite-manager`, { managerEmail, groupName });
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		dispatch(getTenantManagerGroupById(tenantGroupId));
	} catch (error) {
		dispatch(appActions.alert('user:error:invite user', 'warning'));
	}
};

export const resendGroupManagerInvite = (groupId: string, managerEmail: string, groupName: string): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/resend-invite`, { managerEmail, groupName });
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.alert('user:error:resend invite', 'warning'));
	}
};

export const cancelGroupManagerInvite = (groupId: string, managerEmail: string): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/cancel-invite`, { managerEmail });
		dispatch(appActions.alert('tenants:invite canceled', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.handleError(error));
		dispatch(appActions.alert('user:error:cancel invite', 'warning'));
	}
};

export const removeGroupManager = (groupId: string, managerUserId: string): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/remove-manager`, {
			managerUserId
		});
		dispatch(appActions.alert('tenants:manager removed', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.handleError(error));
		dispatch(appActions.alert('user:error:remove user', 'warning'));
	}
};

export const verifyToken = (token: string): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.get(`/api/tenants/${tenantId}/tenant/${token}`);
		if (response.data === 'Token verified!') {
			dispatch({
				type: 'TOKEN_VERIFICATION_SUCCESS',
				payload: { licenseGroupId: tenantId, tokenStatus: 'TOKEN_VERIFIED' }
			});
			dispatch(appActions.alert('Token verified', 'success'));
			return true;
		}
		// dispatch({ type: 'TOKEN_VERIFICATION_SUCCESS', payload: false });
		dispatch({
			type: 'TOKEN_VERIFICATION_FAIL',
			payload: { licenseGroupId: tenantId, tokenStatus: 'TOKEN_ERROR' }
		});

		dispatch(appActions.alert('Token invalid', 'warning'));
		return false;
	} catch (error) {
		dispatch(appActions.handleError(error));
		return false;
	}
};

export const upgradeDemoToProductionLicense = ({
	purchaseCode,
	orderNumber,
	userId,
	// claimManager
	onSuccess = () => {},
	onError = () => {}
}: {
	purchaseCode: string;
	orderNumber: string;
	userId: string;
	// claimManager: boolean;
	onSuccess?: Function;
	onError?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const { data } = await axios.patch(`/api/tenants/${tenantId}/upgrade`, {
			purchaseCode,
			orderNumber,
			userId
			// claimManager
		});

		if (data.success && data.unassigned) {
			dispatch(appActions.alert('License upgraded, but licenses have been unassigned from devices', 'warning'));
		} else if (data.success && !data.unassigned) {
			dispatch(appActions.alert('License upgraded', 'success'));
		}
		onSuccess();
		dispatch(getSelectedLicenseGroupData([]));
		dispatch(getTenantGroupInfo());
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error.response && error.response.status && error.response.status !== 500) {
				if (error.response.status === 403) {
					dispatch(appActions.alert('purchase code:stratus error', 'warning'));
				} else {
					dispatch(appActions.alert('Purchase code invalid', 'warning'));
				}
			} else {
				dispatch(appActions.handleError(error));
			}
		} else {
			dispatch(appActions.handleError(error));
		}
		onError();
	}
};

export const upgradeTermLicense = ({
	capacity,
	userCapacity,
	expirationDate,
	userExpirationDate,
	purchaseCode,
	userId,
	callbackFn
}: {
	capacity: number;
	expirationDate: number;
	userCapacity: number;
	userExpirationDate: number;
	purchaseCode: string;
	userId: string;
	callbackFn?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');
	let success = false;

	try {
		const response = await axios.patch(`/api/tenants/${tenantId}/renew-license`, {
			capacity,
			userCapacity,
			expirationDate,
			userExpirationDate,
			purchaseCode,
			userId
		});
		console.log(response);
		if (response.data.success) {
			dispatch(appActions.alert('License upgraded', 'success'));
			dispatch(getSelectedLicenseGroupData([]));
			success = true;
		} else {
			dispatch(appActions.alert('Purchase code invalid', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('Purchase code invalid', 'warning'));
	} finally {
		if (callbackFn) callbackFn(success);
	}
};

export const modifyTermLicense = ({
	capacity,
	userCapacity,
	expirationDate,
	userExpirationDate,
	purchaseCode,
	userId,
	callbackFn
}: {
	capacity: number;
	expirationDate: number;
	userCapacity: number;
	userExpirationDate: number;
	purchaseCode: string;
	userId: string;
	callbackFn?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const response = await axios.patch(`/api/tenants/${tenantId}/modify-license`, {
			capacity,
			userCapacity,
			expirationDate,
			userExpirationDate,
			purchaseCode,
			userId
		});
		console.log(response);
		if (response.data.success) {
			dispatch(appActions.alert('License modified', 'success'));
			dispatch(getSelectedLicenseGroupData([]));
		} else {
			dispatch(appActions.alert('Failed to modify license', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('Failed to modify license', 'warning'));
	} finally {
		if (callbackFn) callbackFn();
	}
};

export const getTermLicense = (
	purchaseCode: string,
	allowAll: boolean,
	successFn?: Function,
	failFn?: Function
): AppThunk => async (dispatch, getState) => {
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const response = await axios.get(
			allowAll ? `/api/mp/license/order/${purchaseCode}?all=true` : `/api/mp/license/order/${purchaseCode}`
		);
		console.log(response);
		// @ts-ignore
		if (response.data.order) {
			// dispatch(appActions.alert('License upgraded', 'success'));
			// dispatch(getSelectedLicenseGroupData());
			// @ts-ignore
			if (successFn) successFn(response.data.order);
		} else {
			dispatch(appActions.alert('Purchase code invalid', 'warning'));
			if (failFn) failFn();
		}
	} catch (error) {
		dispatch(appActions.alert('Purchase code invalid', 'warning'));
		if (failFn) failFn();
	}
};

export const upgradeLicensePlan = ({
	orderNumber,
	purchaseCode,
	userId,
	keepPanelUserLoggedIn,
	allowPublicUserRemainLoggedIn,
	deleteCookieDomains,
	emailTo,
	callbackFn,
	capacity,
	userCapacity,
	expirationDate,
	userExpirationDate
}: {
	orderNumber: string;
	purchaseCode: string;
	userId: string;
	keepPanelUserLoggedIn: boolean;
	allowPublicUserRemainLoggedIn: boolean;
	deleteCookieDomains: string[];
	emailTo: 'super-admin' | 'admin' | 'all';
	callbackFn?: Function;
	capacity: number;
	userCapacity: number;
	expirationDate: number;
	userExpirationDate: number;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');

	const data = {
		orderNumber,
		purchaseCode,
		userId,
		capacity,
		userCapacity,
		expirationDate,
		userExpirationDate,
		settings: {
			keepPanelUserLoggedIn,
			allowPublicUserRemainLoggedIn,
			deleteCookieDomains,
			emailTo
		}
	};

	try {
		const response = await axios.patch(`/api/tenants/${tenantId}/upgrade-plan`, data);
		console.log(response);
		if (response.data.success) {
			dispatch(appActions.alert('License upgraded', 'success'));
			dispatch(getSelectedLicenseGroupData([]));
			if (callbackFn) callbackFn();
		} else {
			dispatch(appActions.alert('Failed to upgrade license', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('Failed to upgrade license', 'warning'));
	}
};

export const doubleLicenseUpgrade = (purchaseCode: string, secondaryPurchaseCode: string): AppThunk => async (
	dispatch,
	getState
) => {
	console.log(purchaseCode);
	console.log(secondaryPurchaseCode);
};

export const getParagonData = (): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.get(`/api/sso/${tenantId}/paragon-data`);
		console.log(response);
		console.log(response.data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('settings:get paragon data:fail', 'warning'));
		} else {
			dispatch({
				type: GET_PARAGON_DATA_SUCCESS,
				payload: response.data
			});
			dispatch(getSelectedLicenseGroupData([]));
		}
	} catch (error) {
		dispatch(appActions.alert('settings:get paragon data:fail', 'warning'));
	}
};

export const getTenantGroupInfo = (): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	const response = await axios.get(`/api/tenants/${tenantId}/group`);
	// @ts-ignore
	if (responseError(response)) {
		dispatch(appActions.alert('tenant groups:details', 'warning'));
	} else {
		const { data: details } = response;
		dispatch({
			type: GET_SELECTED_TENANT_GROUP_INFO_SUCCESS,
			payload: {
				tenantId,
				data: details
			}
		});
	}
};

export const setNotifications = (groupId: string, arr?: Array<string>): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.post(`/api/tenants/${groupId}/notifications`, {
			arr
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenants:notification:fail', 'warning'));
		} else {
			dispatch(getEditedTenantManagerGroups(groupId));
		}
	} catch (error) {
		dispatch(appActions.alert('tenants:notification:fail', 'warning'));
		console.log('error catch', error);
	}
};

export const setSuspension = (
	tenantId: string,
	obj?: Record<string, boolean>,
	successFn?: Function
): AppThunk => async (dispatch, getState) => {
	if (obj?.terminateTenant) {
		try {
			const response = await axios.post(`/api/tenants/tenant/${tenantId}/terminate`);
			// @ts-ignore
			if (responseError(response)) {
				dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
			} else {
				dispatch(appActions.alert('tenant:manager:delete tenant:success', 'success'));
				if (successFn) {
					successFn(tenantId);
				}
			}
		} catch (error) {
			dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
			console.log('error catch', error);
		}
	} else {
		try {
			const response = await axios.post(`/api/tenants/${tenantId}/suspension`, obj);
			// @ts-ignore
			if (responseError(response)) {
				dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
			} else {
				dispatch(getTenantManagerGroups());
				dispatch(getManagedLicenseGroups());
				dispatch(getTenantById(tenantId));
			}
		} catch (error) {
			dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
			console.log('error catch', error);
		}
	}
};

export const createTenantManagerGroup = (name: string, parentId?: string): AppThunk => async (dispatch, getState) => {
	const groupsById = getTenantManagerGroupsById(getState());
	let pId;
	if (parentId !== undefined) pId = groupsById[parentId];

	try {
		const response = await axios.post('/api/tenants/tenant-manager-groups', {
			name,
			parentId,
			parentName: pId?.name
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenant groups: update fail', 'warning'));
		} else {
			dispatch(getTenantManagerGroups());
		}
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 403) {
			dispatch(appActions.alert('tenant groups: create group reject', 'warning'));
		} else {
			dispatch(appActions.alert('tenant groups: update fail', 'warning'));
		}
	}
};

export const redeemManagerPurchaseCode = (purchaseCode: string, userId: string): AppThunk => async (
	dispatch,
	getState
) => {
	purchaseCode = purchaseCode.replace(/-/g, '');
	try {
		const response = await axios.post('/api/mp/license/redeem', {
			purchaseCode,
			userId
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenant:manager:redeem:failure', 'warning'));
		} else {
			dispatch(getTenantManagerGroups());
			dispatch(appActions.alert('tenant:manager:redeem:success', 'success'));
		}
	} catch (error) {
		dispatch(appActions.alert('tenant:manager:redeem:failure', 'warning'));
	}
};

const updateDashboardCache = (
	region: 'us-east-1' | 'ca-central-1' | 'eu-central-1' | 'ap-northeast-1',
	aggregateGroups: string[]
) => {
	try {
		if (region === 'ca-central-1') {
			console.log('Dashboard not available on ca-central-1');
			throw Error;
		}
		const logApiUrl = getLogApiUrl(region);
		axios.get(`${logApiUrl}/log/metric/dashboard-agg-cache-refresh?aggtenantId=${aggregateGroups.join(',')}`);
	} catch (e) {
		console.log('Error calling dashboard update', e);
	}
};

export const editTenantManagerGroup = (
	name: string,
	id: string,
	parentId?: string,
	onSuccess?: (value: any) => void
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	try {
		const response = await axios.put('/api/tenants/tenant-manager-groups', {
			name,
			id,
			parentId
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenant groups: update fail', 'warning'));
		} else {
			// const { data } = response;
			// const licenseGroup: TenantGroup = massageRawTenantManagerGroup(data);

			// dispatch({
			// 	type: GET_TENANT_GROUP_BY_ID_SUCCESS,
			// 	payload: {
			// 		id,
			// 		data: licenseGroup
			// 	}
			// });
			// const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
			// 	_.keyBy(response.data, 'id'),
			// 	massageRawTenantManagerGroup
			// );
			// dispatch({
			// 	type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
			// 	payload: {
			// 		data: tenantGroups
			// 	}
			// });
			dispatch(getEditedTenantManagerGroups(id));
			if (onSuccess) {
				onSuccess({
					type: 'pushBreadCrumbs',
					payload: {
						name,
						id,
						parent: parentId,
						isGroup: true
					}
				});
			}
		}
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 403) {
			dispatch(appActions.alert('tenant groups: edit group reject', 'warning'));
		} else {
			dispatch(appActions.alert('tenant groups: update fail', 'warning'));
		}
	}
	if (parentId !== undefined) {
		updateDashboardCache(profile.awsRegion, [id, parentId]);
	}
};

export const editManagedTenantDetails = (
	name: string,
	id: string,
	region?: string,
	parentId?: string,
	onSuccess?: (value: any) => void
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	try {
		const response = await axios.put(`/api/tenants/edit-tenant-details/${id}`, {
			name,
			region,
			parentId
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenants:details fail', 'warning'));
		} else {
			dispatch(getManagedLicenseGroups());
			// below was for mock data. probably dont need to do this when the API is done.
			// const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(
			// 	response.data,
			// 	massageRawLicenseGroup
			// );
			// dispatch({
			// 	type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
			// 	payload: {
			// 		data: licenseGroups
			// 	}
			// });
			if (onSuccess) {
				onSuccess({
					type: 'pushBreadCrumbs',
					payload: {
						name,
						id,
						parent: parentId,
						isGroup: false
					}
				});
			}
		}
	} catch (error) {
		dispatch(appActions.alert('tenants:details fail', 'warning'));
	}
	if (parentId !== undefined) {
		updateDashboardCache(profile.awsRegion, [id, parentId]);
	}
};

export const deleteTenantGroup = (id: string): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const groupDetails = Object.values(getTenantManagerGroupsById(getState()));
	const childGroups = groupDetails.reduce((prev: string[], curr: TenantGroup) => {
		if (curr?.parentId === id) return [...prev, curr.id];
		return prev;
	}, []);
	console.log(childGroups);
	try {
		const response = await axios.post(`/api/tenants/tenant-group/${id}/delete`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenant groups: delete fail', 'warning'));
		} else {
			dispatch(getManagedLicenseGroups());
			dispatch(getTenantManagerGroups());
		}
	} catch (error) {
		dispatch(appActions.alert('tenant groups: delete fail', 'warning'));
	}
	updateDashboardCache(profile.awsRegion, childGroups);
};

export const extendLicense = (id: string): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	try {
		const response = await axios.post(`/api/tenants/${id}/extend`);
		// @ts-ignore
		if (responseError(response)) {
			throw new Error('Response error');
		} else {
			dispatch(appActions.alert('tenant:extend:success', 'success'));
			dispatch(getManagedLicenseGroups());
			dispatch(getTenantById(id));
		}
	} catch (error) {
		dispatch(appActions.alert('tenant:extend:fail', 'warning'));
	}
};

export const getTenantManagerGroupById = (groupId: string): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.get(`/api/tenants/tenant-manager-group/${groupId}`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenant groups:details', 'warning'));
		} else {
			const { data: EmailInfo } = response;
			dispatch({
				type: GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS,
				payload: {
					groupId,
					data: EmailInfo
				}
			});
		}
	} catch (error) {
		dispatch(appActions.alert('tenant groups:details', 'warning'));
	}
};

export const setLoadingTenantGroups = (): AppThunk => async (dispatch, getState) => {
	dispatch({
		type: SET_LOADING_MANAGER_GROUPS,
		payload: {
			loading: true
		}
	});
};

export const saveFormAuthSettings = (obj: object): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.postForm(`/api/tenants/saml/${tenantId}/client-data`, obj, {
			headers: {
				'Content-Type': 'Content-Type: multipart/form-data'
			}
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form auth fail', 'warning'));
		} else {
			dispatch(appActions.alert('form auth add', 'success'));
		}
	} catch (error) {
		dispatch(appActions.alert('form auth fail', 'warning'));
	}
};

export const updateFormAuthSettings = (updateObject: object): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.patchForm(`/api/tenants/saml/${tenantId}/client-data`, updateObject, {
			headers: {
				'Content-Type': 'Content-Type: multipart/form-data'
			}
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form auth fail', 'warning'));
		} else {
			dispatch(appActions.alert('form auth edit', 'success'));
		}
	} catch (error) {
		dispatch(appActions.alert('form auth fail', 'warning'));
	}
};

export const deleteFormAuthProvider = (name: string, type: 'sso-user' | 'sso-non-user'): AppThunk => async (
	dispatch,
	getState
) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.post(`/api/tenants/saml/${tenantId}/delete/client-data`, {
			providerName: name,
			type
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('form auth fail', 'warning'));
		} else {
			dispatch(appActions.alert('form auth delete', 'success'));
		}
	} catch (error) {
		dispatch(appActions.alert('form auth fail', 'warning'));
	}
};
